import React, { useState } from 'react';
import Link from 'next/link';
import styles from '../styles/games.module.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import Image from 'next/image';

export default function Games({ initialGames = [], searchQuery }) {
  const [games, setGames] = useState(initialGames);

  const filteredGames = searchQuery 
    ? games.filter((game) =>
        game.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : games;


  return (
    <section className={styles.games}>
      <div className={styles.gameList} id="game-list">
        {filteredGames.map((game, index) => (
          <Link className={styles.gameItem} href={`/${game.slug}`} key={game.id}>
            <div className={styles.gameBlock}>
              <div className={styles.badge}>
                <FontAwesomeIcon 
                  icon={faBolt} 
                  className={`${styles.iGreenL} ${styles.iF13}`} 
                  style={{ width: '10px', height: '13px' }} 
                />
              </div>
              
              <div className={styles.Test}>
                <Image
                  fill
                  src={game.image}
                  alt={`Обложка ${game.name}`}
                  quality={75}
                  priority={index==0}
                />
              </div>
            </div>
            <div>
              <p className={styles.gameName}>{game.name}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
}
